import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AgChartsReact } from 'ag-charts-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './component.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

function SymbolDetails() {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const gridRef = useRef();
  const navigate = useNavigate();
  const chartContainerRef = useRef(null);
  const overlayCanvasRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [syms, setSyms] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState('option2');
  const [numberOfDays, setNumberOfDays] = useState('');
  const [symbolInput, setSymbolInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const params = useParams();
  const symbol = params.symbol || "";

  // Fetch symbols for autocomplete
  useEffect(() => {
    const fetchSymbols = async () => {
      const symbolsUrl = `${process.env.REACT_APP_API_HOST}/api/symbols`;
      try {
        const symbolsResponse = await fetch(symbolsUrl);
        if (symbolsResponse.ok) {
          const symbolsData = await symbolsResponse.json();
          setSyms(symbolsData);
        } else {
          console.error("Failed to fetch symbols.");
        }
      } catch (error) {
        console.error("Error fetching symbols:", error);
      }
    };
    fetchSymbols();
  }, []);

  const popupParent = useMemo(() => document.body, []);
  const onBtnExport = useCallback(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.exportDataAsCsv();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      if (overlayCanvasRef.current && chartContainerRef.current) {
        const container = chartContainerRef.current;
        overlayCanvasRef.current.width = container.offsetWidth;
        overlayCanvasRef.current.height = container.offsetHeight;
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Filter chart data based on time selection
  useEffect(() => {
    const dateNow = new Date();
    let startDate;
    if (selectedOption === 'option1') {
      startDate = new Date(dateNow);
      startDate.setMonth(startDate.getMonth() - 2);
    } else if (selectedOption === 'option2') {
      startDate = new Date(dateNow);
      startDate.setMonth(startDate.getMonth() - 6);
    } else if (selectedOption === 'option3') {
      startDate = new Date(dateNow);
      startDate.setMonth(startDate.getMonth() - 12);
    } else if (selectedOption === 'option4') {
      if (numberOfDays !== '') {
        const parsedDays = parseInt(numberOfDays, 10);
        if (!isNaN(parsedDays) && parsedDays > 0) {
          startDate = new Date();
          startDate.setDate(startDate.getDate() - parsedDays);
        }
      }
    }

    if (startDate && rowData.length > 0) {
      const filteredData = rowData
        .filter((entry) => {
          const [year, month, day] = entry.market_date.split('-').map(Number);
          const entryDate = new Date(year, month - 1, day);
          return entryDate >= startDate;
        })
        .sort((a, b) => new Date(a.market_date) - new Date(b.market_date));
      setChartData(filteredData);
    }
  }, [selectedOption, numberOfDays, rowData]);

  function isFriday(dateString) {
    if (!dateString) return false;
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    return date.getDay() === 5;
  }

  const createColumnDefs = (width) => {
    return width > 450
      ? [
          {
            headerName: 'Symbol:',
            field: 'symbol',
            filter: true,
            flex: 1,
            headerClass: 'header-center',
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Company Name:',
            field: 'company_name',
            filter: true,
            flex: 1,
            headerClass: 'header-center',
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Market Date:',
            field: 'market_date',
            filter: true,
            flex: 1,
            headerClass: 'header-center',
            valueFormatter: (params) => {
              const [year, month, day] = params.value.split('-').map(Number);
              return `${month}/${day}/${year.toString().slice(-2)}`;
            },
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Closing Price:',
            field: 'closing_price',
            flex: 1,
            headerClass: 'header-center',
            cellRenderer: (params) => {
              const closingPrice = parseFloat(params.data.closing_price).toFixed(2);
              return <div>${closingPrice}</div>;
            },
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Volume:',
            field: 'closing_volume',
            flex: 1,
            headerClass: 'header-center',
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Daily Trend:',
            field: 'daily_trend',
            filter: true,
            flex: 1,
            headerClass: 'header-center',
            cellRenderer: (params) => {
              const trendValue = params.data.daily_trend;
              const relativePercentage = params.data.trend_relative_percentage;
              let color = '#000';
              if (trendValue > 0) color = '#0ca81e';
              else if (trendValue < 0) color = 'red';
              return (
                <div style={{ color: color }}>
                  {trendValue} | <span>{relativePercentage != null ? `${relativePercentage}%` : 'N/A'}</span>
                </div>
              );
            },
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Smoothed:',
            field: 'smoothed_trend',
            filter: true,
            flex: 1,
            headerClass: 'header-center',
            cellRenderer: (params) => {
              const trendValue = params.data.smoothed_trend;
              const relativePercentage = params.data.smoothed_relative_percentage;
              let color = '#000';
              if (trendValue > 0) color = '#0ca81e';
              else if (trendValue < 0) color = 'red';
              return (
                <div style={{ color: color }}>
                  {trendValue} | <span>{relativePercentage != null ? `${relativePercentage}%` : 'N/A'}</span>
                </div>
              );
            },
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Adjusted %:',
            field: 'adjusted_trend_rp',
            flex: 1,
            headerClass: 'header-center',
            cellRenderer: (params) => {
              const adjustedRP = params.data.adjusted_trend_rp;
              if (adjustedRP == null) return 'N/A';
              let color = '#000';
              if (adjustedRP > 0) color = '#0ca81e';
              else if (adjustedRP < 0) color = 'red';
              return <div style={{ color: color }}>{adjustedRP}%</div>;
            },
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Smoothed Adj%:',
            field: 'adjusted_smoothed_rp',
            flex: 1,
            headerClass: 'header-center',
            cellRenderer: (params) => {
              const adjustedSmoothRP = params.data.adjusted_smoothed_rp;
              if (adjustedSmoothRP == null) return 'N/A';
              let color = '#000';
              if (adjustedSmoothRP > 0) color = '#0ca81e';
              else if (adjustedSmoothRP < 0) color = 'red';
              return <div style={{ color: color }}>{adjustedSmoothRP}%</div>;
            },
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
        ]
      : [
          {
            headerName: 'Market Date:',
            field: 'market_date',
            filter: true,
            flex: 1,
            headerClass: 'header-center',
            valueFormatter: (params) => {
              const [year, month, day] = params.value.split('-').map(Number);
              return `${month}/${day}/${year.toString().slice(-2)}`;
            },
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Closing Price:',
            field: 'closing_price',
            flex: 1,
            headerClass: 'header-center',
            cellRenderer: (params) => {
              const closingPrice = parseFloat(params.data.closing_price).toFixed(2);
              return <div>${closingPrice}</div>;
            },
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Daily Trend:',
            field: 'daily_trend',
            filter: true,
            flex: 1,
            headerClass: 'header-center',
            cellRenderer: (params) => {
              const trendValue = params.data.daily_trend;
              const relativePercentage = params.data.trend_relative_percentage;
              let color = '#000';
              if (trendValue > 0) color = '#0ca81e';
              else if (trendValue < 0) color = 'red';
              return (
                <div style={{ fontSize: '10px', color: color }}>
                  {trendValue} | <span>{relativePercentage != null ? `${relativePercentage}%` : 'N/A'}</span>
                </div>
              );
            },
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Smoothed:',
            field: 'smoothed_trend',
            filter: true,
            flex: 1,
            headerClass: 'header-center',
            cellRenderer: (params) => {
              const trendValue = params.data.smoothed_trend;
              const relativePercentage = params.data.smoothed_relative_percentage;
              let color = '#000';
              if (trendValue > 0) color = '#0ca81e';
              else if (trendValue < 0) color = 'red';
              return (
                <div style={{ fontSize: '10px', color: color }}>
                  {trendValue} | <span>{relativePercentage != null ? `${relativePercentage}%` : 'N/A'}</span>
                </div>
              );
            },
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Adjusted %:',
            field: 'adjusted_trend_rp',
            flex: 1,
            headerClass: 'header-center',
            cellRenderer: (params) => {
              const adjustedRP = params.data.adjusted_trend_rp;
              if (adjustedRP == null) return 'N/A';
              let color = '#000';
              if (adjustedRP > 0) color = '#0ca81e';
              else if (adjustedRP < 0) color = 'red';
              return (
                <div style={{ fontSize: '10px', color: color }}>
                  {adjustedRP}%
                </div>
              );
            },
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Smoothed %:',
            field: 'adjusted_smoothed_rp',
            flex: 1,
            headerClass: 'header-center',
            cellRenderer: (params) => {
              const adjustedSmoothRP = params.data.adjusted_smoothed_rp;
              if (adjustedSmoothRP == null) return 'N/A';
              let color = '#000';
              if (adjustedSmoothRP > 0) color = '#0ca81e';
              else if (adjustedSmoothRP < 0) color = 'red';
              return (
                <div style={{ fontSize: '10px', color: color }}>
                  {adjustedSmoothRP}%
                </div>
              );
            },
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
        ];
  };

  const columnDefs = createColumnDefs(width);

  const gridStyle = {
    width: '100%',
    maxWidth: '1360px',
    height: 'calc(100vh - 370px)',
    overflowX: 'auto',
    marginBottom: '.5rem',
  };

  const gridContainerStyle = `
    .header-center .ag-header-cell-label {
      justify-content: center;
    }
  `;

  const [PriceChart, setPriceChart] = useState({
    autoSize: true,
    data: [],
    legend: { enabled: true, position: 'bottom' },
    series: [
      {
        type: 'line',
        xKey: 'market_date',
        yKey: 'closing_price',
        title: 'Closing Price',
        stroke: '#800080',
        marker: { enabled: true, size: 4, fill: '#800080' },
      },
    ],
    axes: [
      { type: 'category', position: 'bottom' },
      {
        type: 'number',
        position: 'left',
        crossLines: [
          { value: 0, strokeWidth: 2, stroke: '#00008B', lineDash: [6, 3] },
        ],
      },
    ],
  });

  const [TrendChart, setTrendChart] = useState({
    autoSize: true,
    data: [],
    legend: { enabled: true, position: 'bottom' },
    series: [
      {
        type: 'line',
        xKey: 'market_date',
        yKey: 'daily_trend',
        title: 'Daily Trend',
        stroke: '#FF5349',
        marker: { enabled: true, size: 4, fill: '#FF5349' },
      },
      {
        type: 'line',
        xKey: 'market_date',
        yKey: 'smoothed_trend',
        title: 'Smoothed Trend',
        stroke: '#FFB427',
        marker: { enabled: true, size: 4, fill: '#FFB427' },
      },
    ],
    axes: [
      { type: 'category', position: 'bottom' },
      {
        type: 'number',
        position: 'left',
        crossLines: [
          { value: 0, strokeWidth: 2, stroke: '#00008B', lineDash: [6, 3] },
        ],
      },
    ],
  });

  useEffect(() => {
    setPriceChart((prevOptions) => ({
      ...prevOptions,
      data: chartData,
    }));
  }, [chartData]);

  useEffect(() => {
    setTrendChart((prevOptions) => ({
      ...prevOptions,
      data: chartData,
    }));
  }, [chartData]);

  const defaultColDef = useMemo(() => ({ sortable: true }), []);
  const cellClickedListener = useCallback(() => {}, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!symbol) return;
      try {
        const token = await getAccessTokenSilently({
          audience: "https://uptrenddowntrend.com/api",
          scope: "openid profile email",
        });

        const result = await fetch(`${process.env.REACT_APP_API_HOST}/api/symbol_data/${symbol}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!result.ok) {
          throw new Error(`Network response was not ok. Status: ${result.status}`);
        }

        const data = await result.json();
        setRowData(data);
      } catch (error) {
        console.error('Fetch operation error:', error.message);
      }
    };

    fetchData();
  }, [symbol, getAccessTokenSilently]);

  const handleSymbolInputChange = (e) => {
    const input = e.target.value.toUpperCase();
    setSymbolInput(input);
    if (input.length > 0) {
      // Make sure text is visible by ensuring color is black
      const filteredSuggestions = syms
        .filter((s) => s.symbol.toUpperCase().startsWith(input))
        .slice(0, 5);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (sym) => {
    setSymbolInput(sym);
    setSuggestions([]);
    navigate(`/details/${sym}`);
  };

  const search = symbol.toUpperCase();
  const symbolHolder = syms.map((s) => s.symbol);
  const [quickFilterText, setQuickFilterText] = useState('');

  let trendHigh = 0;
  let trendLow = 0;
  let smoothedHigh = 0;
  let smoothedLow = 0;
  let trendRP = 0;
  let smoothedRP = 0;

  if (rowData.length > 0) {
    const recentData = rowData.slice(0, 65);
    trendHigh = Math.max(...recentData.map((d) => d.daily_trend));
    trendLow = Math.min(...recentData.map((d) => d.daily_trend));
    smoothedHigh = Math.max(...recentData.map((d) => d.smoothed_trend));
    smoothedLow = Math.min(...recentData.map((d) => d.smoothed_trend));

    const mostRecentData = recentData[0];
    if (mostRecentData) {
      const dailyTrendData = mostRecentData.daily_trend;
      if (dailyTrendData > 0) {
        trendRP = ((dailyTrendData / trendHigh) * 100).toFixed(2);
      } else if (dailyTrendData < 0) {
        trendRP = ((dailyTrendData / trendLow) * 100).toFixed(2);
      }

      const dailySmoothedData = mostRecentData.smoothed_trend;
      if (dailySmoothedData > 0) {
        smoothedRP = ((dailySmoothedData / smoothedHigh) * 100).toFixed(2);
      } else if (dailySmoothedData < 0) {
        smoothedRP = ((dailySmoothedData / smoothedLow) * 100).toFixed(2);
      }
    }
  }

  useEffect(() => {
    if (!overlayCanvasRef.current || !chartContainerRef.current) return;
    const canvas = overlayCanvasRef.current;
    const ctx = canvas.getContext('2d');

    const updateCanvasSize = () => {
      if (overlayCanvasRef.current && chartContainerRef.current) {
        const container = chartContainerRef.current;
        canvas.width = container.offsetWidth;
        canvas.height = container.offsetHeight;
      }
    };

    updateCanvasSize();

    const handleMouseMove = (event) => {
      const rect = chartContainerRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      ctx.moveTo(x, 0);
      ctx.lineTo(x, canvas.height);
      ctx.strokeStyle = 'rgba(255, 0, 0, 0.7)';
      ctx.lineWidth = 1;
      ctx.stroke();
    };

    const handleMouseLeave = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    };

    chartContainerRef.current.addEventListener('mousemove', handleMouseMove);
    chartContainerRef.current.addEventListener('mouseleave', handleMouseLeave);
    window.addEventListener('resize', updateCanvasSize);

    return () => {
      if (chartContainerRef.current) {
        chartContainerRef.current.removeEventListener('mousemove', handleMouseMove);
        chartContainerRef.current.removeEventListener('mouseleave', handleMouseLeave);
      }
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, [overlayCanvasRef.current, chartContainerRef.current]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <>
      <style>{gridContainerStyle}</style>
      {symbolHolder.includes(search) ? (
        <div
          className="symbol-details-main"
          style={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#001f3f',
            minHeight: '100vh',
            color: 'white'
          }}
        >
          <div
            style={{
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '45%',
              marginTop: '1rem'
            }}
          >
            {syms.map((symbolItem) => {
              if (symbolItem.symbol === search) {
                return (
                  <div key={symbolItem.symbol} className="symbol_items_container">
                    <ul className="symbol_items">
                      <li className="symbol_title">{symbolItem.symbol}</li>
                      <li className="symbol_cp_name">{symbolItem.company_name}</li>
                      <li className="symbol_exchange">{symbolItem.exchange}</li>
                      <li className="symbol_link">
                        {symbolItem.website ? (
                          <a href={symbolItem.website} target="_blank" rel="noopener noreferrer">
                            {symbolItem.website}
                          </a>
                        ) : (
                          'No Website Available'
                        )}
                      </li>
                    </ul>
                  </div>
                );
              }
              return null;
            })}
            {width > 450 ? (
              <div
                style={{
                  display: 'flex',
                  color: 'white',
                  border: '1px solid white',
                  minWidth: '15%',
                  padding: '1rem',
                  borderRadius: '5px',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  height: '142px',
                  marginLeft: '1rem'
                }}
              >
                <div style={{ textTransform: 'uppercase', color: '#00b1ff' }}>
                  Uptrend_Downtrend Predictors:
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ marginRight: '.5rem' }}>
                    <h3 style={{ textDecoration: 'underline' }}>Daily Trend</h3>
                    {rowData.length > 0 && (
                      <>
                        <h6 style={{ display: 'flex', fontSize: '10px' }}>
                          Current:{' '}
                          <span
                            style={{
                              marginLeft: '2px',
                              color: rowData[0].daily_trend > 0 ? '#0ca81e' : '#ff0e0e',
                            }}
                          >
                            {rowData[0].daily_trend}
                          </span>
                        </h6>
                        <h6 style={{ display: 'flex', fontSize: '10px' }}>High: {trendHigh}</h6>
                        <h6 style={{ display: 'flex', fontSize: '10px' }}>Low: {trendLow}</h6>
                        <h6 style={{ display: 'flex', fontSize: '10px' }}>
                          Relative Percentage:{' '}
                          <span
                            style={{
                              marginLeft: '2px',
                              color: rowData[0].daily_trend > 0 ? '#0ca81e' : '#ff0e0e',
                            }}
                          >
                            {trendRP}%
                          </span>
                        </h6>
                      </>
                    )}
                  </div>
                  <div style={{ marginLeft: '.5rem' }}>
                    <h3 style={{ textDecoration: 'underline' }}>Smoothed Trend</h3>
                    {rowData.length > 0 && (
                      <>
                        <h6 style={{ display: 'flex', fontSize: '10px' }}>
                          Current:{' '}
                          <span
                            style={{
                              marginLeft: '2px',
                              color: rowData[0].smoothed_trend > 0 ? '#0ca81e' : '#ff0e0e',
                            }}
                          >
                            {rowData[0].smoothed_trend}
                          </span>
                        </h6>
                        <h6 style={{ display: 'flex', fontSize: '10px' }}>High: {smoothedHigh}</h6>
                        <h6 style={{ display: 'flex', fontSize: '10px' }}>Low: {smoothedLow}</h6>
                        <h6 style={{ display: 'flex', fontSize: '10px' }}>
                          Relative Percentage:{' '}
                          <span
                            style={{
                              marginLeft: '2px',
                              color: rowData[0].smoothed_trend > 0 ? '#0ca81e' : '#ff0e0e',
                            }}
                          >
                            {smoothedRP}%
                          </span>
                        </h6>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          {width < 450 && rowData.length > 0 ? (
            <div
              style={{
                display: 'flex',
                color: 'white',
                border: '1px solid white',
                minWidth: '30%',
                padding: '1rem',
                borderRadius: '5px',
                marginBottom: '1rem',
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: '1rem'
              }}
            >
              <div style={{ textTransform: 'uppercase', color: '#00b1ff' }}>
                Uptrend_Downtrend Predictors:
              </div>
              <div style={{ padding: '.5rem' }}>
                <h3 style={{ textDecoration: 'underline' }}>Daily Trend</h3>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>
                  Current:{' '}
                  <span
                    style={{
                      marginLeft: '2px',
                      color: rowData[0].daily_trend > 0 ? '#0ca81e' : '#ff0e0e',
                    }}
                  >
                    {rowData[0].daily_trend}
                  </span>
                </h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>High: {trendHigh}</h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>Low: {trendLow}</h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>
                  Relative Percentage:{' '}
                  <span
                    style={{
                      marginLeft: '2px',
                      color: rowData[0].daily_trend > 0 ? '#0ca81e' : '#ff0e0e',
                    }}
                  >
                    {trendRP}%
                  </span>
                </h6>
              </div>
              <div style={{ padding: '.5rem' }}>
                <h3 style={{ textDecoration: 'underline' }}>Smoothed Trend</h3>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>
                  Current:{' '}
                  <span
                    style={{
                      marginLeft: '2px',
                      color: rowData[0].smoothed_trend > 0 ? '#0ca81e' : '#ff0e0e',
                    }}
                  >
                    {rowData[0].smoothed_trend}
                  </span>
                </h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>High: {smoothedHigh}</h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>Low: {smoothedLow}</h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>
                  Relative Percentage:{' '}
                  <span
                    style={{
                      marginLeft: '2px',
                      color: rowData[0].smoothed_trend > 0 ? '#0ca81e' : '#ff0e0e',
                    }}
                  >
                    {smoothedRP}%
                  </span>
                </h6>
              </div>
            </div>
          ) : null}

          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '.5rem', marginTop: '1rem' }}>
            <div style={{ position: 'relative', marginRight: '1rem' }}>
              <input
                type="text"
                placeholder="Jump to Symbol"
                value={symbolInput}
                onChange={handleSymbolInputChange}
                style={{
                  color: 'black',
                  padding: '0.5rem',
                  borderRadius: '5px',
                  width: '150px',
                  marginBottom: '0.5rem',
                }}
              />
              {suggestions.length > 0 && (
                <ul
                  style={{
                    listStyleType: 'none',
                    padding: 0,
                    margin: 0,
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    width: '150px',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    color: 'black' // ensure black text color for visibility
                  }}
                >
                  {suggestions.map((suggestion) => (
                    <li
                      key={suggestion.symbol}
                      onClick={() => handleSuggestionClick(suggestion.symbol)}
                      style={{
                        padding: '0.5rem',
                        cursor: 'pointer',
                        color: 'black' // black text for suggestions
                      }}
                    >
                      {suggestion.symbol}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <button
              style={{
                color: 'white',
                border: '1px solid white',
                padding: '.3rem',
                borderRadius: '5px',
                backgroundColor: '#134f42',
              }}
              onClick={onBtnExport}
            >
              Export as CSV
            </button>
            <button
              style={{
                color: 'white',
                border: '1px solid white',
                padding: '.3rem',
                borderRadius: '5px',
                backgroundColor: '#134f42',
                marginLeft: '0.5rem',
              }}
              onClick={() => navigate(`/predictor/${symbol}`)}
            >
              Predictor
            </button>
          </div>

          {width > 450 ? (
            <div className="ag-theme-alpine" style={gridStyle}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowSelection="multiple"
                rowMultiSelectWithClick={true}
                cellSelection={true}
                enableCellTextSelection={true}
                ensureDomOrder={true}
                onCellClicked={() => {}}
                quickFilterText={quickFilterText}
                suppressExcelExport={true}
                popupParent={popupParent}
              />
            </div>
          ) : (
            <div
              className="ag-theme-alpine"
              style={{
                width: '100%',
                height: 'calc(100vh - 470px)',
                overflowX: 'auto',
                marginBottom: '.5rem',
                fontSize: '14px',
              }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowSelection="multiple"
                rowMultiSelectWithClick={true}
                cellSelection={true}
                enableCellTextSelection={true}
                ensureDomOrder={true}
                onCellClicked={() => {}}
                quickFilterText={quickFilterText}
              />
            </div>
          )}

          <div style={{ color: 'white', width: '100%', maxWidth: '1000px', marginTop: '1rem' }}>
            <div className="details-container" style={{ margin: '0 auto', textAlign: 'center' }}>
              <div style={{ marginBottom: '.5rem' }}>
                <button
                  style={{
                    color: 'white',
                    border: '1px solid white',
                    padding: '.3rem',
                    borderRadius: '5px',
                    backgroundColor: '#134f42',
                    marginRight: '1rem'
                  }}
                  onClick={() => {
                    window.open(`/details_charts/${symbol}`, '_blank', 'noopener,noreferrer');
                  }}
                >
                  Open Charts View
                </button>
                <span style={{ textTransform: 'uppercase', color: '#00b1ff', fontSize: '24px', verticalAlign: 'middle' }}>
                  Charts:
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '15px',
                  marginBottom: '.5rem',
                }}
              >
                <div>
                  <input
                    type="radio"
                    id="option1"
                    name="options"
                    value="option1"
                    style={{ marginRight: '.2rem' }}
                    onChange={() => setSelectedOption('option1')}
                    checked={selectedOption === 'option1'}
                  />
                  <label htmlFor="option1" style={{ marginRight: '.5rem' }}>
                    Previous Month
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="option2"
                    name="options"
                    value="option2"
                    style={{ marginRight: '.2rem' }}
                    onChange={() => setSelectedOption('option2')}
                    checked={selectedOption === 'option2'}
                  />
                  <label htmlFor="option2" style={{ marginRight: '.5rem' }}>
                    Previous 6 Months
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="option3"
                    name="options"
                    value="option3"
                    style={{ marginRight: '.2rem' }}
                    onChange={() => setSelectedOption('option3')}
                    checked={selectedOption === 'option3'}
                  />
                  <label htmlFor="option3" style={{ marginRight: '.5rem' }}>
                    Previous Year
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="option4"
                    name="options"
                    value="option4"
                    style={{ marginRight: '.2rem' }}
                    onChange={() => setSelectedOption('option4')}
                    checked={selectedOption === 'option4'}
                  />
                  <input
                    type="number"
                    id="option4"
                    placeholder="Enter # of Days"
                    name="options"
                    value={numberOfDays}
                    style={{
                      marginRight: '.2rem',
                      color: 'black',
                      borderRadius: '2px',
                    }}
                    onChange={(e) => setNumberOfDays(e.target.value)}
                    onClick={() => setSelectedOption('option4')}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  ref={chartContainerRef}
                  style={{ position: 'relative', width: '100%', maxWidth: '1000px' }}
                >
                  <div
                    className="details-chart"
                    style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}
                  >
                    <div style={{ margin: '.5rem' }}>
                      <AgChartsReact options={TrendChart} />
                    </div>
                    <div style={{ margin: '.5rem' }}>
                      <AgChartsReact options={PriceChart} />
                    </div>
                  </div>
                  <canvas
                    ref={overlayCanvasRef}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      pointerEvents: 'none',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="error-container" style={{ backgroundColor: '#001f3f', minHeight: '100vh', color: 'white' }}>
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'Center',
              paddingTop: '2rem',
              color: 'white',
              fontSize: '2rem',
            }}
          >
            SYMBOL DOES NOT EXIST
          </div>
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'Center',
              paddingTop: '2rem',
              color: 'white',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h6>Would you like to add this symbol?</h6>
            <button
              className="no-symbol-btn"
              style={{
                border: '1px solid white',
                maxWidth: '9rem',
                padding: '.7rem',
                borderRadius: '5px',
                marginTop: '.5rem',
                backgroundColor: '#134f42',
                color: 'white'
              }}
            >
              <Link to="/dashboard/controlpanel" style={{ color: 'white', textDecoration: 'none' }}>ADD SYMBOL</Link>
            </button>
          </div>
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'Center',
              paddingTop: '2rem',
              color: 'white',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h6>Search for a different symbol</h6>
            <button
              className="no-symbol-btn"
              style={{
                border: '1px solid white',
                maxWidth: '12rem',
                padding: '.8rem',
                borderRadius: '5px',
                marginTop: '.5rem',
                backgroundColor: '#134f42',
                color: 'white'
              }}
            >
              <Link to="/details" style={{ color: 'white', textDecoration: 'none' }}>SEARCH SYMBOL</Link>
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default SymbolDetails;
