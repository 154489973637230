import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './component.css';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';

function Symbols() {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [collectionsData, setCollectionsData] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState("");
  const [quickFilterText, setQuickFilterText] = useState('');

  const popupParent = useMemo(() => document.body, []);
  const onBtnExport = useCallback(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.exportDataAsCsv();
    }
  }, []);

  useEffect(() => {
    const fetchCollections = async () => {
      const token = await getAccessTokenSilently({
        audience: "https://uptrenddowntrend.com/api",
        scope: "openid profile email",
      });
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/collections`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setCollectionsData(data);
      } else {
        console.error("Error fetching collections data");
      }
    };

    fetchCollections();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchRecentData = async () => {
      const token = await getAccessTokenSilently({
        audience: "https://uptrenddowntrend.com/api",
        scope: "openid profile email",
        ignoreCache: true
      });
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/symbol_data/recent`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setRowData(data);
        setFullData(data);
      } else {
        console.error("Error fetching initial symbol data");
      }
    };

    fetchRecentData();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const storedCollection = localStorage.getItem('selectedCollection');
    if (storedCollection !== null) {
      setSelectedCollection(storedCollection);
    }
  }, []);

  const handleCollectionChange = (collection) => {
    setSelectedCollection(collection);
    localStorage.setItem('selectedCollection', collection);
  };

  useEffect(() => {
    const fetchCollectionData = async () => {
      if (selectedCollection) {
        const intSelectedCollection = parseInt(selectedCollection, 10);
        try {
          const token = await getAccessTokenSilently({
            audience: "https://uptrenddowntrend.com/api",
            scope: "openid profile email",
          });
          const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/collections/link/${intSelectedCollection}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          if (response.ok) {
            const collectionData = await response.json();
            if (Array.isArray(collectionData)) {
              const symbolIds = collectionData.map(item => item.symbol_id);
              const filteredRowData = fullData.filter(row => symbolIds.includes(row.symbol_id));
              setRowData(filteredRowData);
            } else {
              console.error("Response data is not an array:", collectionData);
            }
          } else {
            console.error("Error fetching data for selected collection");
          }
        } catch (error) {
          console.error("Error fetching collection data:", error);
        }
      } else {
        setRowData(fullData);
      }
    };

    fetchCollectionData();
  }, [selectedCollection, getAccessTokenSilently, fullData]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const createColumnDefs = (width) => {
    return width > 950
      ? [
          {
            headerName: 'Symbol:',
            field: 'symbol',
            filter: true,
            flex: 1,
            maxWidth: 135,
            cellRenderer: function (params) {
              const symbol = params.data.symbol;
              return (
                <Link to={`/details/${symbol}`} className="symbol-header">
                  {symbol}
                </Link>
              );
            },
          },
          {
            headerName: 'Company Name:',
            field: 'company_name',
            filter: true,
            flex: 1,
            maxWidth: 250,
            cellRenderer: function (params) {
              const symbol = params.data.symbol;
              const companyName = params.data.company_name;
              return (
                <Link to={`/details/${symbol}`} className="symbol-header">
                  {companyName}
                </Link>
              );
            },
          },
          {
            headerName: 'Market Date:',
            field: 'market_date',
            filter: true,
            flex: 1,
            maxWidth: 150,
          },
          {
            headerName: 'Closing Price:',
            field: 'closing_price',
            flex: 1,
            maxWidth: 150,
            cellRenderer: function (params) {
              const closingPrice = params.data.closing_price;
              return <div>${closingPrice}</div>;
            },
          },
          {
            headerName: 'Closing Volume:',
            field: 'closing_volume',
            flex: 1,
            maxWidth: 150,
          },
          {
            headerName: 'Daily Trend:',
            field: 'daily_trend',
            filter: true,
            flex: 1,
            maxWidth: 150,
            cellStyle: (params) => {
              if (params.value > 0) return { color: '#0ca81e' };
              else if (params.value < 0) return { color: 'red' };
              else return { color: '#000' };
            },
          },
          {
            headerName: 'Smoothed Trend:',
            field: 'smoothed_trend',
            filter: true,
            flex: 1,
            maxWidth: 175,
            cellStyle: (params) => {
              if (params.value > 0) return { color: '#0ca81e' };
              else if (params.value < 0) return { color: 'red' };
              else return { color: '#000' };
            },
          },
        ]
      : [
          {
            headerName: 'Symbol:',
            field: 'symbol',
            filter: true,
            flex: 1,
            maxWidth: 135,
            cellRenderer: function (params) {
              const symbol = params.data.symbol;
              return (
                <Link to={`/details/${symbol}`} className="symbol-header">
                  {symbol}
                </Link>
              );
            },
          },
          {
            headerName: 'Closing Price:',
            field: 'closing_price',
            flex: 1,
            maxWidth: 150,
            cellRenderer: function (params) {
              const closingPrice = params.data.closing_price;
              return <div>${closingPrice}</div>;
            },
          },
          {
            headerName: 'Daily Trend:',
            field: 'daily_trend',
            filter: true,
            flex: 1,
            maxWidth: 150,
            cellStyle: (params) => {
              if (params.value > 0) return { color: '#0ca81e' };
              else if (params.value < 0) return { color: 'red' };
              else return { color: '#000' };
            },
          },
          {
            headerName: 'Smoothed Trend:',
            field: 'smoothed_trend',
            filter: true,
            flex: 1,
            maxWidth: 175,
            cellStyle: (params) => {
              if (params.value > 0) return { color: '#0ca81e' };
              else if (params.value < 0) return { color: 'red' };
              else return { color: '#000' };
            },
          },
        ];
  };

  const columnDefs = createColumnDefs(width);
  const defaultColDef = useMemo(() => ({ sortable: true }), []);
  const cellClickedListener = useCallback((event) => {}, []);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  let positiveCount = 0;
  let negativeCount = 0;
  for (let i = 0; i < rowData.length; i++) {
    if (rowData[i].daily_trend > 0) {
      positiveCount += 1;
    } else if (rowData[i].daily_trend < 0) {
      negativeCount += 1;
    }
  }

  // Determine the current collection name
  let currentCollectionName = "All Symbols";
  if (selectedCollection) {
    const currentCollection = collectionsData.find(c => c.collection_id === parseInt(selectedCollection, 10));
    if (currentCollection && currentCollection.collection_name) {
      currentCollectionName = currentCollection.collection_name;
    }
  }

  return (
    <div
      className="symbols-main"
      style={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: width > 450 ? 'flex' : 'block',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          marginBottom: '1rem',
        }}
      >
        <div
          style={{
            color: 'white',
            border: '1px solid white',
            padding: '.3rem',
            fontSize: '14px',
            margin: '.2rem',
            borderRadius: '5px',
            minWidth: '200px',
            maxWidth: '300px',
            wordWrap: 'break-word'
          }}
        >
          <h2 style={{ margin: '0 0 .5rem 0', fontSize: '16px' }}>Symbol Navigation:</h2>
          <p style={{ margin: 0, lineHeight: '1.2em' }}>
            View the entire universe of tracked symbols or select a collection below.
            Click any symbol to open detailed analysis.
          </p>
        </div>
        <div
          style={{
            color: 'white',
            border: '1px solid white',
            padding: '.3rem',
            fontSize: '12px',
            margin: '.2rem',
            borderRadius: '5px',
            minWidth: '200px',
          }}
        >
          <h1>{currentCollectionName} Overall Trends:</h1>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              margin: '.2rem',
            }}
          >
            <div style={{ marginRight: '.3rem' }}>
              <h1 style={{ textDecoration: 'underline' }}>POSITIVES (+)</h1>
              <p style={{ color: 'rgb(12, 168, 30)' }}>{positiveCount}</p>
            </div>
            <div style={{ marginLeft: '.3rem' }}>
              <h1 style={{ textDecoration: 'underline' }}>NEGATIVES (-)</h1>
              <p style={{ color: 'red' }}>{negativeCount}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Container for Collection, Search and Buttons on the same line */}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          maxWidth: '900px',
          marginBottom: '1rem',
        }}
      >
        {/* Collection Selection */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '1rem', marginBottom: width > 450 ? 0 : '1rem' }}>
          <h4
            style={{
              color: '#FFF',
              textTransform: 'uppercase',
              fontSize: '14px',
              marginBottom: '.5rem',
              textAlign: 'center'
            }}
          >
            Select Collection
          </h4>
          <select
            id="chooseCollection"
            value={selectedCollection}
            onChange={(e) => handleCollectionChange(e.target.value)}
            style={{
              color: 'black',
              padding: '.3rem',
              borderRadius: '2px',
              minWidth: '150px'
            }}
          >
            <option value="">ALL SYMBOLS</option>
            {collectionsData.map((collection) => (
              <option
                style={{ textAlign: 'center' }}
                key={collection.collection_id}
                value={collection.collection_id}
              >
                {collection.collection_name}
              </option>
            ))}
          </select>
        </div>

        {/* Symbol Search */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '1rem', marginBottom: width > 450 ? 0 : '1rem' }}>
          <h4
            style={{
              color: '#FFF',
              textTransform: 'uppercase',
              fontSize: '14px',
              marginBottom: '.5rem',
              textAlign: 'center'
            }}
          >
            Search in Collection:
          </h4>
          <input
            className="all-symbols-input"
            style={{
              padding: '0.3rem',
              width: '80%',
              maxWidth: '200px',
            }}
            type="text"
            placeholder="Search"
            onChange={(e) => setQuickFilterText(e.target.value)}
          />
        </div>

        {/* Filter and Export Buttons */}
        <div style={{ display: 'flex', alignItems: 'center', marginTop: width > 450 ? 0 : '1rem' }}>
          <button
            style={{
              color: 'white',
              border: '1px solid white',
              padding: '.3rem',
              marginRight: '1rem',
              borderRadius: '5px',
              backgroundColor: '#134f42',
            }}
          >
            <Link
              to="/filter"
              style={{ color: 'white', textDecoration: 'none' }}
            >
              Open Filter View
            </Link>
          </button>
          <button
            style={{
              color: 'white',
              border: '1px solid white',
              padding: '.3rem',
              borderRadius: '5px',
              backgroundColor: '#134f42',
            }}
            onClick={onBtnExport}
          >
            Export as CSV
          </button>
        </div>
      </div>

      <div
        className="ag-theme-alpine"
        style={{
          width: '80%',
          height: 'calc(100vh - 200px)',
          overflowX: 'auto',
          fontSize: '10px',
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          rowMultiSelectWithClick={true}
          cellSelection={true}
          enableCellTextSelection={true}
          ensureDomOrder={true}
          onCellClicked={cellClickedListener}
          quickFilterText={quickFilterText}
          suppressExcelExport={true}
          popupParent={popupParent}
        />
      </div>
    </div>
  );
}

export default Symbols;
