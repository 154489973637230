import React, { useState, useRef, useEffect } from 'react';
import { AgChartsReact } from 'ag-charts-react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

function DetailsChartsOnly() {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const { symbol: paramSymbol } = useParams();
  const symbol = paramSymbol ? paramSymbol.toUpperCase() : "";
  const chartContainerRef = useRef(null);
  const overlayCanvasRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [syms, setSyms] = useState([]);
  const [selectedOption, setSelectedOption] = useState('option2');
  const [numberOfDays, setNumberOfDays] = useState('');

  // Fetch symbols for additional info
  useEffect(() => {
    const fetchSymbols = async () => {
      const symbolsUrl = `${process.env.REACT_APP_API_HOST}/api/symbols`;

      try {
        const symbolsResponse = await fetch(symbolsUrl);
        if (symbolsResponse.ok) {
          const symbolsData = await symbolsResponse.json();
          setSyms(symbolsData);
        } else {
          console.error("Failed to fetch symbols.");
        }
      } catch (error) {
        console.error("Error fetching symbols:", error);
      }
    };
    fetchSymbols();
  }, []);

  // Fetch symbol data
  useEffect(() => {
    const fetchData = async () => {
      if (!symbol) return;
      try {
        const token = await getAccessTokenSilently({
          audience: "https://uptrenddowntrend.com/api",
          scope: "openid profile email",
        });

        const result = await fetch(`${process.env.REACT_APP_API_HOST}/api/symbol_data/${symbol}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!result.ok) {
          throw new Error(`Network response was not ok. Status: ${result.status}`);
        }

        const data = await result.json();
        setRowData(data);
      } catch (error) {
        console.error('Fetch operation error:', error.message);
      }
    };
    fetchData();
  }, [symbol, getAccessTokenSilently]);

  // Filter chart data based on selectedOption and numberOfDays
  useEffect(() => {
    if (rowData.length === 0) return;

    const dateNow = new Date();
    let startDate;

    if (selectedOption === 'option1') {
      startDate = new Date(dateNow);
      startDate.setMonth(startDate.getMonth() - 2);
    } else if (selectedOption === 'option2') {
      startDate = new Date(dateNow);
      startDate.setMonth(startDate.getMonth() - 6);
    } else if (selectedOption === 'option3') {
      startDate = new Date(dateNow);
      startDate.setMonth(startDate.getMonth() - 12);
    } else if (selectedOption === 'option4') {
      if (numberOfDays !== '') {
        const parsedDays = parseInt(numberOfDays, 10);
        if (!isNaN(parsedDays) && parsedDays > 0) {
          startDate = new Date();
          startDate.setDate(startDate.getDate() - parsedDays);
        }
      }
    }

    if (startDate) {
      const filteredData = rowData
        .filter((entry) => {
          const [year, month, day] = entry.market_date.split('-').map(Number);
          const entryDate = new Date(year, month - 1, day);
          return entryDate >= startDate;
        })
        .sort((a, b) => new Date(a.market_date) - new Date(b.market_date));
      setChartData(filteredData);
    } else {
      const sortedData = [...rowData].sort((a, b) => new Date(a.market_date) - new Date(b.market_date));
      setChartData(sortedData);
    }
  }, [rowData, selectedOption, numberOfDays]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      if (overlayCanvasRef.current && chartContainerRef.current) {
        const container = chartContainerRef.current;
        overlayCanvasRef.current.width = container.offsetWidth;
        overlayCanvasRef.current.height = container.offsetHeight;
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function isFriday(dateString) {
    if (!dateString) return false;
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    return date.getDay() === 5;
  }

  let trendHigh = 0;
  let trendLow = 0;
  let smoothedHigh = 0;
  let smoothedLow = 0;
  let trendRP = 0;
  let smoothedRP = 0;

  if (rowData.length > 0) {
    const recentData = rowData.slice(0, 65);
    trendHigh = Math.max(...recentData.map((d) => d.daily_trend));
    trendLow = Math.min(...recentData.map((d) => d.daily_trend));
    smoothedHigh = Math.max(...recentData.map((d) => d.smoothed_trend));
    smoothedLow = Math.min(...recentData.map((d) => d.smoothed_trend));

    const mostRecentData = recentData[0];
    if (mostRecentData) {
      const dailyTrendData = mostRecentData.daily_trend;
      if (dailyTrendData > 0) {
        trendRP = ((dailyTrendData / trendHigh) * 100).toFixed(2);
      } else if (dailyTrendData < 0) {
        trendRP = ((dailyTrendData / trendLow) * 100).toFixed(2);
      }

      const dailySmoothedData = mostRecentData.smoothed_trend;
      if (dailySmoothedData > 0) {
        smoothedRP = ((dailySmoothedData / smoothedHigh) * 100).toFixed(2);
      } else if (dailySmoothedData < 0) {
        smoothedRP = ((dailySmoothedData / smoothedLow) * 100).toFixed(2);
      }
    }
  }

  const [PriceChart, setPriceChart] = useState({
    autoSize: true,
    data: [],
    legend: { enabled: true, position: 'bottom' },
    series: [
      {
        type: 'line',
        xKey: 'market_date',
        yKey: 'closing_price',
        title: 'Closing Price',
        stroke: '#800080',
        marker: { enabled: true, size: 4, fill: '#800080' },
      },
    ],
    axes: [
      { type: 'category', position: 'bottom' },
      {
        type: 'number',
        position: 'left',
        crossLines: [{ value: 0, strokeWidth: 2, stroke: '#00008B', lineDash: [6, 3] }],
      },
    ],
  });

  const [TrendChart, setTrendChart] = useState({
    autoSize: true,
    data: [],
    legend: { enabled: true, position: 'bottom' },
    series: [
      {
        type: 'line',
        xKey: 'market_date',
        yKey: 'daily_trend',
        title: 'Daily Trend',
        stroke: '#FF5349',
        marker: { enabled: true, size: 4, fill: '#FF5349' },
      },
      {
        type: 'line',
        xKey: 'market_date',
        yKey: 'smoothed_trend',
        title: 'Smoothed Trend',
        stroke: '#FFB427',
        marker: { enabled: true, size: 4, fill: '#FFB427' },
      },
    ],
    axes: [
      { type: 'category', position: 'bottom' },
      {
        type: 'number',
        position: 'left',
        crossLines: [{ value: 0, strokeWidth: 2, stroke: '#00008B', lineDash: [6, 3] }],
      },
    ],
  });

  useEffect(() => {
    setPriceChart((prevOptions) => ({
      ...prevOptions,
      data: chartData,
    }));
  }, [chartData]);

  useEffect(() => {
    setTrendChart((prevOptions) => ({
      ...prevOptions,
      data: chartData,
    }));
  }, [chartData]);

  // Overlay line on charts
  useEffect(() => {
    if (!overlayCanvasRef.current || !chartContainerRef.current) return;
    const canvas = overlayCanvasRef.current;
    const ctx = canvas.getContext('2d');

    const updateCanvasSize = () => {
      if (overlayCanvasRef.current && chartContainerRef.current) {
        const container = chartContainerRef.current;
        canvas.width = container.offsetWidth;
        canvas.height = container.offsetHeight;
      }
    };

    updateCanvasSize();

    const handleMouseMove = (event) => {
      const rect = chartContainerRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      ctx.moveTo(x, 0);
      ctx.lineTo(x, canvas.height);
      ctx.strokeStyle = 'rgba(255, 0, 0, 0.7)';
      ctx.lineWidth = 1;
      ctx.stroke();
    };

    const handleMouseLeave = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    };

    chartContainerRef.current.addEventListener('mousemove', handleMouseMove);
    chartContainerRef.current.addEventListener('mouseleave', handleMouseLeave);
    window.addEventListener('resize', updateCanvasSize);

    return () => {
      if (chartContainerRef.current) {
        chartContainerRef.current.removeEventListener('mousemove', handleMouseMove);
        chartContainerRef.current.removeEventListener('mouseleave', handleMouseLeave);
      }
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, [overlayCanvasRef.current, chartContainerRef.current]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const symbolInfo = syms.find((s) => s.symbol === symbol);

  return (
    <div
      style={{
        backgroundColor: '#001f3f',
        minHeight: '100vh',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      {rowData.length > 0 ? (
        <>
          {/* Move Charts Only View Header to top */}
          <div style={{ textAlign: 'center', marginTop: '1rem' }}>
            <span style={{ textTransform: 'uppercase', color: '#00b1ff', fontSize: '24px' }}>Charts Only View</span>
          </div>

          {/* Symbol and Predictors Info */}
          <div
            style={{
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '45%',
              marginTop: '1rem'
            }}
          >
            {symbolInfo && (
              <div className="symbol_items_container">
                <ul className="symbol_items">
                  <li className="symbol_title">{symbolInfo.symbol}</li>
                  <li className="symbol_cp_name">{symbolInfo.company_name}</li>
                  <li className="symbol_exchange">{symbolInfo.exchange}</li>
                  <li className="symbol_link">
                    {symbolInfo.website ? (
                      <a href={symbolInfo.website} target="_blank" rel="noopener noreferrer">
                        {symbolInfo.website}
                      </a>
                    ) : (
                      'No Website Available'
                    )}
                  </li>
                </ul>
              </div>
            )}

            {rowData.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  color: 'white',
                  border: '1px solid white',
                  minWidth: '15%',
                  padding: '1rem',
                  borderRadius: '5px',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  height: '142px',
                  marginLeft: '1rem'
                }}
              >
                <div style={{ textTransform: 'uppercase', color: '#00b1ff' }}>
                  Uptrend_Downtrend Predictors:
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ marginRight: '.5rem' }}>
                    <h3 style={{ textDecoration: 'underline' }}>Daily Trend</h3>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>
                      Current:{' '}
                      <span
                        style={{
                          marginLeft: '2px',
                          color: rowData[0].daily_trend > 0 ? '#0ca81e' : '#ff0e0e',
                        }}
                      >
                        {rowData[0].daily_trend}
                      </span>
                    </h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>High: {trendHigh}</h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>Low: {trendLow}</h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>
                      Relative Percentage:{' '}
                      <span
                        style={{
                          marginLeft: '2px',
                          color: rowData[0].daily_trend > 0 ? '#0ca81e' : '#ff0e0e',
                        }}
                      >
                        {trendRP}%
                      </span>
                    </h6>
                  </div>
                  <div style={{ marginLeft: '.5rem' }}>
                    <h3 style={{ textDecoration: 'underline' }}>Smoothed Trend</h3>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>
                      Current:{' '}
                      <span
                        style={{
                          marginLeft: '2px',
                          color: rowData[0].smoothed_trend > 0 ? '#0ca81e' : '#ff0e0e',
                        }}
                      >
                        {rowData[0].smoothed_trend}
                      </span>
                    </h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>High: {smoothedHigh}</h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>Low: {smoothedLow}</h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>
                      Relative Percentage:{' '}
                      <span
                        style={{
                          marginLeft: '2px',
                          color: rowData[0].smoothed_trend > 0 ? '#0ca81e' : '#ff0e0e',
                        }}
                      >
                        {smoothedRP}%
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Radio selectors and custom input for number of days */}
          <div style={{ textAlign: 'center', marginTop: '2rem' }}>
            <div style={{ display: 'inline-block', fontSize: '15px', marginBottom: '.5rem', color: 'white' }}>
              <div style={{ display: 'inline-block', marginRight: '1rem' }}>
                <input
                  type="radio"
                  id="option1"
                  name="options"
                  value="option1"
                  style={{ marginRight: '.2rem' }}
                  onChange={() => setSelectedOption('option1')}
                  checked={selectedOption === 'option1'}
                />
                <label htmlFor="option1" style={{ marginRight: '.5rem' }}>Previous Month</label>
              </div>
              <div style={{ display: 'inline-block', marginRight: '1rem' }}>
                <input
                  type="radio"
                  id="option2"
                  name="options"
                  value="option2"
                  style={{ marginRight: '.2rem' }}
                  onChange={() => setSelectedOption('option2')}
                  checked={selectedOption === 'option2'}
                />
                <label htmlFor="option2" style={{ marginRight: '.5rem' }}>Previous 6 Months</label>
              </div>
              <div style={{ display: 'inline-block', marginRight: '1rem' }}>
                <input
                  type="radio"
                  id="option3"
                  name="options"
                  value="option3"
                  style={{ marginRight: '.2rem' }}
                  onChange={() => setSelectedOption('option3')}
                  checked={selectedOption === 'option3'}
                />
                <label htmlFor="option3" style={{ marginRight: '.5rem' }}>Previous Year</label>
              </div>
              <div style={{ display: 'inline-block' }}>
                <input
                  type="radio"
                  id="option4"
                  name="options"
                  value="option4"
                  style={{ marginRight: '.2rem' }}
                  onChange={() => setSelectedOption('option4')}
                  checked={selectedOption === 'option4'}
                />
                <input
                  type="number"
                  id="option4"
                  placeholder="Enter # of Days"
                  name="options"
                  value={numberOfDays}
                  style={{
                    marginRight: '.2rem',
                    color: 'black',
                    borderRadius: '2px',
                    width: '150px'
                  }}
                  onChange={(e) => setNumberOfDays(e.target.value)}
                  onClick={() => setSelectedOption('option4')}
                />
              </div>
            </div>
          </div>

          {/* Charts Section */}
          <div style={{ width: '100%', maxWidth: '1000px', marginTop: '1rem' }}>
            <div
              ref={chartContainerRef}
              style={{ position: 'relative', width: '100%', maxWidth: '1000px', margin: '0 auto' }}
            >
              <div
                className="details-chart"
                style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}
              >
                <div style={{ margin: '.5rem' }}>
                  <AgChartsReact options={TrendChart} />
                </div>
                <div style={{ margin: '.5rem' }}>
                  <AgChartsReact options={PriceChart} />
                </div>
              </div>
              <canvas
                ref={overlayCanvasRef}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  pointerEvents: 'none',
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <div style={{ backgroundColor: '#001f3f', minHeight: '100vh', color: 'white' }}>
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'Center',
              paddingTop: '2rem',
              color: 'white',
              fontSize: '2rem',
            }}
          >
            SYMBOL DOES NOT EXIST
          </div>
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'Center',
              paddingTop: '2rem',
              color: 'white',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h6>Would you like to add this symbol?</h6>
            <button
              style={{
                border: '1px solid white',
                maxWidth: '9rem',
                padding: '.7rem',
                borderRadius: '5px',
                marginTop: '.5rem',
                backgroundColor: '#134f42',
                color: 'white'
              }}
            >
              ADD SYMBOL
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DetailsChartsOnly;
